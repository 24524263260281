import React, { useState, useEffect, useRef } from "react";
import { MenuButton } from "../MenuButton/MenuButton";
import { useClickOutside } from "../../hooks/useClickOutside";
import "./Header.css";
import { Link } from "react-router-dom";

export const Header = (proops) => {
    const [isOpen, setOpen] = useState('');
    const tooggleMenu = () => {
        if (isOpen == '') {
            setOpen('active');
        } else {
            setOpen('');
        }
    }
    return (
        <header className={`header ${isOpen}`}>
            <div className="container">
                <div className="flex-betwen flex-wrap header-search-wrap">
                    <div className="menu-container-btn">
                        <button className="btn-menu" onClick={() => { tooggleMenu() }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="search-block">
                        <input type="text" placeholder="search or filter by tag" />
                    </div>
                </div>
            </div>
            <div className="menu-content">
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li>
                                <Link to="/">new polls</Link>
                            </li>
                            <li>
                                <Link to="/">hot polls</Link>
                            </li>
                            <li>
                                <Link to="/">top polls</Link>
                            </li>
                            <li>
                                <Link to="/">voted polls</Link>
                            </li>
                            <li>
                                <Link to="/">my own polls</Link>
                            </li>
                            <li>
                                <Link to="/">favorites</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li className="grey-text">
                                gender: male
                            </li>
                            <li className="grey-text">
                                date of birth: 1987
                            </li>
                            <li className="grey-text">
                                country: France
                            </li>
                            <li>
                                <Link to="/">
                                    copy for migrate to new device
                                </Link>
                                <span className="grey-text">(and we advise to save it)</span>
                            </li>
                            <li>
                                <Link to="/" className="copy-link">
                                    sshhsgjkhk2h4kjhkfhsfskfdh
                                </Link>

                            </li>
                        </ul>
                    </div>
                </div>
                <div className="borders-content">
                    <div className="container">
                        <ul className="menu-list">
                            <li>
                                <Link to="/">
                                    privacy policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    terms of use
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="blue-bottom container">
                    <div className="text-block">
                        add your own questions for $0.99
                    </div>
                    <div className="btn-subscribe">
                        <Link className="btn-white" to="/"> subscribe</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;