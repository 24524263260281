import React from 'react';

function ChoseAge(props) {
    return (
        <div className={"AfterQuestionGeneralPercentChoseAge"}>
            <div className={"AfterQuestionGeneralPercentSpaceBetween AfterQuestionGeneralChoseGenderChosen"}>to 24</div>
            <div className={"AfterQuestionGeneralPercentSpaceBetween AfterQuestionGeneralChoseGenderSpaceBetween"}>25-45</div>
            <div className={"AfterQuestionGeneralPercentSpaceBetween"}>form 45</div>
        </div>
    );
}

export default ChoseAge;