import React, {useState} from 'react';
import './firstQuestionResult.css';
import AfterQuestionGeneral from '../afterQuestion/afterQuestionGeneral/afterQuestionGeneral';
import AfterQuestionGeneralPercent from "../afterQuestion/afterQuestionGeneralPercent/afterQuestionGeneralPercent";
import Footer from "../Footer/Footer";


function firstQuestionResultContent(props) {
    const submit = 'none';
    const margin = 'marginTopZero';

    return (
        <>
            <div className={"firstQuestionResultContent"}>
                <div className={"firstQuestionResultContentTitle"}>
                    <div className={"firstQuestionResultContentTitleContent"}>
                        Perfect! Let’s see what answered
                        people around the World.
                        And what are the people
                    </div>
                </div>

                <div className={"firstQuestionResultContentBlock1"}>
                    Do you have a pet?
                </div>

                <hr/>

                <AfterQuestionGeneral page={"default"}></AfterQuestionGeneral>
                <AfterQuestionGeneralPercent></AfterQuestionGeneralPercent>

                <div className={"firstQuestionResultContentLetsgo"}>to main screen</div>

                <Footer submitValue={submit} marginValue={margin}></Footer>
            </div>
        </>
    )
}

export default firstQuestionResultContent;