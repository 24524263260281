const Get = {

    url: 'https://blancapp.10sec.ru/request',
    //url: 'http://localhost:8010/proxy/request',
    key: 'dkfjhkj234hf934',
    userhash: localStorage.getItem("hash"),
    objectToForm: (ob) => {
        let formData = new FormData();
        for (let key in ob) {
            formData.append(key, ob[key]);
        }
        return formData;
    },
    post: async (method, data) => {

        let res = { type: 'error', status: 500 };
        try {
            let response = await fetch(Get.url + '/' + method, {
                method: "POST",
                body: Get.objectToForm(data),
            });



            res = await response.json().catch(() => {

            });


        } catch (e) {

        }
        return res;
    },
    get: async (method) => {
        let res = { 'type': 'error', status: 500 };

        try {
            let response = await fetch(Get.url + '/' + method, {
                method: "GET",
            });
            res = await response.json().catch(() => {

            });
        } catch (e) {

        }
        return res;
    },
    dateFormatTimestapTime: (data) => {


        // let date = new Date(Number(unix) * 1000);
        let unix = Math.floor(new Date(data).getTime() / 1000);
        let dateUnix = new Date(Number(unix) * 1000);
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let optionstime = { hour: 'numeric', minute: 'numeric' };
        return dateUnix.toLocaleString('ru-RU', options) + ' ' + dateUnix.toLocaleTimeString('ru-RU', optionstime);
    }

}
export default Get;