import React, { useRef, useState, useEffect } from 'react';
import './Notification.css'
import Get from '../../Fech/Get';
function Notification(props) {
    const [message, setMessage] = useState(null);
    const getMessage = async () => {
        let data = await Get.post('get_notifications', {
            key: Get.key,
            hash: Get.userhash,
            // tag: 'All'
        });
        if (data !== false) {
            setMessage(data);
        }
    }
    useEffect(() => {
        getMessage();
    }, [])
    return (
        <div className={"Notification"}>

            {message !== null ?
                <>
                    <img src={require('./notification.svg').default} alt="" />
                    <pre className={"Notification_pre"}>{message}</pre>
                </>
                : null}
        </div>
    );
}

export default Notification;