import React from 'react';
import Header from "../Header/Header";
import './subscribedUserQuestion.css';

function SubscribedUserQuestion(props) {
    return (
        <div>
            <Header showHide={'none'}></Header>

            <div className={"main"}>
                <div className={"SubscribedUserQuestionBlock1"}>My first time |</div>

                <hr className={"SubscribedUserQuestionBlock1Hr"}/>

                <div className={"SubscribedUserQuestionBlock2"}>Yes</div>
                <div className={"SubscribedUserQuestionBlock2"}>No</div>
                <div className={"SubscribedUserQuestionBlock2"}>Officially no</div>

                <div className={"SubscribedUserQuestionBlock3"}>add 3 tags, separated, by comma</div>

                <div className={"SubscribedUserQuestionFooter"}></div>
                <div className={"inputButton"}>
                    <img src={require('../../images/Frame.svg').default} alt=""/>
                    <input type="submit" value="add answer"/>
                </div>

                <div className={"SubscribedUserQuestionBlock4"}>
                    <div className={"SubscribedUserQuestionBlock4Title"}>ready to publish?</div>
                    <div className={"SubscribedUserQuestionBlock4Submit"}>yes, submit</div>
                </div>
            </div>
        </div>
    );
}

export default SubscribedUserQuestion;