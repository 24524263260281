import { Navigate, Outlet, useLocation } from "react-router-dom";

const AnonimRoute = () => {

    var Autch = localStorage.getItem('hash');
    const location = useLocation();
    return (
        Autch === null ?
            <Outlet />
            :
            <Navigate to="/" state={{ from: location }} replace />
    );
};
export default AnonimRoute;