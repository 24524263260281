import React from 'react';
import FirstQuestionResultContent from '../components/firstQuestionResult/firstQuestionResult';
import Pages from "../components/pages";

function FirstQuestionResult(props) {
    return (
        <div>
            <FirstQuestionResultContent></FirstQuestionResultContent>

            <Pages></Pages>
        </div>
    );
}

export default FirstQuestionResult;