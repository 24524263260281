import React from 'react';

function ChoseGender(props) {
    return (
        <div className={"AfterQuestionGeneralChoseGender"}>
            <div className={"AfterQuestionGeneralChoseGenderChosen"}>male</div>
            <div>female</div>
            <div>other</div>
        </div>
    );
}

export default ChoseGender;