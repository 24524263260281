import React, { useRef, useState, useEffect } from 'react';
import Slider from '../MainSlider/MainSlider';
import Notification from '../Notification/Notification'
import Slider2 from '../MainSlider2/MainSlider2';
import QuestionsListTabs from '../QuestionsListTabs/QuestionsListTabs';
import Header from "../Header/Header";
import Pages from "../pages";
import Get from '../../Fech/Get';

const Main = (props) => {


    const question = useRef(null);
    const getQuestion = async () => {
        let data = await Get.post('get_questions_list', {
            key: Get.key,
            hash: Get.userhash,
            // tag: 'All'
        });
        return data;
    }

    const setQuestion = (data) => {

        if (question.current !== null) {
            question.current.questionSet(data);
        }
    }
    useEffect(() => {
        const as = async () => {
            let data = await getQuestion();
            setQuestion(data);
        }
        as();
    }, [])
    return (
        <>
            <Header></Header>

            <div className={"main"}>
                <Slider></Slider>
                <Notification></Notification>
                <Slider2></Slider2>
                <QuestionsListTabs ref={question}></QuestionsListTabs>
            </div>

            <Pages></Pages>
        </>
    );
}

export default Main;