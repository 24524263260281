import React from 'react';
import {Link} from "react-router-dom";

function Pages(props) {
    return (
        <div>
            <nav className={"nav"}>
                <ul>
                    <li>
                        <Link to={'/'}>main</Link>
                    </li>
                    <li>
                        <Link to={'/questionpage'}>QuestionPage</Link>
                    </li>
                    <li>
                        <Link to={'/questionpageafterquestion'}>AfterQuestion</Link>
                    </li>
                    <li>
                        <Link to={'/QuestionPageAfterQuestionGender'}>QuestionGender</Link>
                    </li>
                    <li>
                        <Link to={'/QuestionPageAfterQuestionAge'}>QuestionAge</Link>
                    </li>
                    <li>
                        <Link to={'/QuestionPageAfterQuestionMap'}>QuestionMap</Link>
                    </li>
                    <li>
                        <Link to={'/Registration'}>Registration</Link>
                    </li>
                    <li>
                        <Link to={'/firstQuestion'}>firstQuestion</Link>
                    </li>
                    <li>
                        <Link to={'/firstQuestionResult'}>firstQuestionResult</Link>
                    </li>
                    <li>
                        <Link to={'/subscribedUserQuestion'}>SubscribedUserQuestion</Link>
                    </li>
                    <li>
                        <Link to={'/subscribePage'}>SubscribePage</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Pages;