import React from 'react';
import "../../../pages/css.css";

function QuestionPage(props) {
    const submit = 'share question';

    return (
        <>
            <div className={"mainQuestion"}>Have you had covid?</div>

            <div className={"votesTimeFavorite"}>
                <div>
                    <img src={require('../../../images/question.svg').default} alt=""/>
                    <pre>5 votes</pre>
                </div>
                <div>
                    <img src={require('../../../images/time.svg').default} alt=""/>
                    yesterday
                </div>
                <div>
                    <img src={require('../../../images/favorite.svg').default} alt=""/>
                    in favorites
                </div>
            </div>

            <hr/>
        </>
    );
}

export default QuestionPage;