import React from 'react';
import RegistrationContent from '../components/Registration/RegistrationContent';
import Pages from "../components/pages";

function Registration(props) {
    return (
        <div>
            <RegistrationContent></RegistrationContent>

            <Pages></Pages>
        </div>
    );
}

export default Registration;