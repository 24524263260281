import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './RegistrationContent.css';
import Get from '../../Fech/Get';

function RegistrationContent(props) {
    const minmax = {
        minValue: 0,
        maxValue: 100,
    };
    const location = useLocation();
    const [price, setPrice] = useState(25);
    const [nav, setNav] = useState(0);
    // const handleChangeNumber = (e) => {
    //     const numbers = e.target.value;
    //     setPrice(numbers);
    // };
    const handleChangeRange = (e) => {
        const numbers = e.target.value;
        setPrice(numbers);
    };
    const submitForm = async (e) => {
        e.preventDefault();

        e.target.classList.add("loaded");
        let formEntries = new FormData(e.target).entries();
        let data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
        data.key = Get.key;
        let result = await Get.post('registration', data);
        e.target.classList.remove("loaded");
        if (result.hasOwnProperty('hash')) {
            localStorage.setItem("hash", result.hash);
            setNav(1)
        } else {
            alert('Registration failed');
        }
    }
    return nav == 1 ? <Navigate to="/" state={{ from: location }} replace /> : (


        <>
            <div className={"RegistrationContent"}>
                <form onSubmit={(e) => { submitForm(e) }}>
                    <div className={"RegistrationContentTitle"}>A little about you</div>

                    <div className={"RegistrationContentGender"}>Gender</div>

                    <div className={"RegistrationContentSelect"}>
                        <select name="gender">
                            <option value='Men'>Men</option>
                            <option value='Woman'>Woman</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>

                    <div className={"RegistrationContentGender"}>Country living</div>

                    <div className={"RegistrationContentSelect"}>
                        <select name='country'>
                            <option value='Armenia'>Armenia</option>
                            <option value='Russia'>Russia</option>
                            <option value='USA'>USA</option>
                        </select>
                    </div>

                    <div className={"RegistrationContentAge"}>Age</div>
                    <div className={"RegistrationContentAgeRange"}>
                        <div className={"RegistrationContentAgePrice"}>
                            {price}
                        </div>

                        <input
                            type="range"
                            min={minmax.minValue}
                            max={minmax.maxValue}
                            name="age"
                            onChange={
                                (e) => handleChangeRange(e)
                            }
                            value={price}
                        />
                    </div>

                    <button type='submit' className={"RegistrationContentLetsgo"}>let’s go!</button>

                    <div className={"RegistrationContentFooter"}>
                        {/*<img src={require('../../images/Logo1.svg').default} alt=""/>*/}
                    </div>
                </form>
            </div>
        </>

    )
}

export default RegistrationContent;