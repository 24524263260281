import React from 'react';
import "../../../pages/css.css";

function AfterQuestionGeneralPercent(props) {

    if (props.page !== 'point') {
        return (
            <div className={"AfterQuestionGeneralPercent"}>
                <div className={"AfterQuestionGeneralPercentYes"}>
                    <div className={"AfterQuestionGeneralPercentYesDiv"}>
                        Yes - <pre> 45%</pre>
                    </div>
                </div>

                <div className={"AfterQuestionGeneralPercentNo"}>
                    <div className={"AfterQuestionGeneralPercentNoDiv"}>
                        No - <pre> 30%</pre>
                    </div>
                </div>

                <div className={"AfterQuestionGeneralPercentOfficiallyNo"}>
                    <div className={"AfterQuestionGeneralPercentOfficiallyNoPre1"}>Officially n</div>
                    <span>o -</span>
                    <pre> 25%</pre>
                </div>
            </div>
        );
    }
}

export default AfterQuestionGeneralPercent;