import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './MainSlider2.css';
import Get from '../../Fech/Get';
import { Link } from "react-router-dom";
export default function App() {
    const [sliders, setSliders] = useState(null);
    const getSlider1 = async () => {
        let data = await Get.post('get_questions_u_list', {
            key: Get.key,
            hash: Get.userhash,
            // tag: 'All'
        });
        if (data.hasOwnProperty('aboba')) {
            setSliders(data.aboba);
        }

    }
    useEffect(() => {
        getSlider1();
    }, [])
    return (
        <>
            {sliders == null ?
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper MainSlider2"
                >
                    <SwiperSlide>
                        <div className="MainSlider__Item2  loaded">

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="MainSlider__Item2  loaded">

                        </div>
                    </SwiperSlide>



                </Swiper> :
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper MainSlider2"
                >
                    {
                        sliders.map((el, index) => {
                            return <SwiperSlide key={index}>

                                <Link to={`/questionpage?id=${el.id}`} className={`MainSlider__Item2 ${el.activity == 1 ? 'backgroudRed' : ''}`}>
                                    <div className={"MainSlider__Item__img3 "}>
                                        <img src={el.logo} alt="" className={"SaySVG"} />
                                    </div>
                                    <div className={"MainSlider__Item__text3"}>
                                        {el.name}
                                    </div>
                                    <div className={"MainSlider__Item__time2"}>
                                        <img src={require("./time.svg").default} alt="" />
                                        <pre>{Get.dateFormatTimestapTime(el.date)}</pre>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        })}
                </Swiper>}
        </>
    );
}