import React, { useState, useEffect } from 'react';
import Header from "../components/Header/Header";
import './css.css';

import Pages from "../components/pages";
import { Link } from "react-router-dom";
import Get from '../Fech/Get';
import QuestionForm from '../components/firstQuestion/QuestionForm'
import QuestionNoform from '../components/afterQuestion/QestionNoform';
function QuestionPage(props) {
    const submit = 'submit';
    const [question, setQuestion] = useState({
        question_name: '',
        respondents: '',
        answers: [],
        reply: null

    });
    const getContent = async () => {
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');
        if (order_id) {
            let loaded = document.querySelector(".loaded-block")
            loaded.classList.add("loaded");
            let data = await Get.post('get_question', {
                key: Get.key,
                hash: Get.userhash,
                question_id: order_id
            });
            loaded.classList.remove("loaded");

            setQuestion({
                question_name: data.question_name,
                respondents: data.respondents,
                answers: data.answers,
                reply: data.reply
            });
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');
        if (order_id) {
            let loaded = document.querySelector(".loaded-block")
            loaded.classList.add("loaded");
            let formEntries = new FormData(e.target).entries();
            let data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
            data.key = Get.key;
            data.hash = Get.userhash;
            data.question_id = order_id;
            let result = await Get.post('save_question', data);
            //console.log(result);
            loaded.classList.remove("loaded");
            setQuestion({
                ...question,
                reply: 1
            })
        }
    }
    useEffect(
        () => {
            const as = async () => {
                await getContent();
            }
            as();
        }, []
    );
    return (
        <>
            <Header></Header>
            <div className='loaded-block'>
                {question.reply == 0 ? <QuestionForm question={question} submitForm={(e) => submitForm(e)} /> : ''}
                {question.reply == 1 ? <QuestionNoform question={question} /> : ''}
            </div>
            <Pages></Pages>
        </>
    );
}

export default QuestionPage;