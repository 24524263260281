import React from 'react';

function ChosePoint(props) {
    return (
        <div className={"AfterQuestionGeneralChosePoint"}>
            <img
                src={require('../../images/arrow_left.svg').default}
                alt=""
            />
            <div>Yes</div>
            <img
                src={require('../../images/arrow_right.svg').default}
                alt=""
            />
        </div>
    );
}

export default ChosePoint;