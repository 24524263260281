import React from 'react';
import "../../../pages/css.css";
import './css.css';
import ChoseGender from "../choseGender";
import ChoseAge from "../choseAge";
import ChosePoint from "../chosePoint";

function content(page) {
    if (page === 'default') {
        return  "";
    }
    else if(page === 'gender'){
        return  <ChoseGender></ChoseGender>
    }
    else if(page === 'age'){
        return  <ChoseAge></ChoseAge>
    }
    else if(page === 'point'){
        return  <ChosePoint></ChosePoint>
    }
}


function AfterQuestionGeneral(props) {
    // console.log(props.page)
    return (
        <div className={
            props.page !== 'point' ? "AfterQuestionGeneral" : "AfterQuestionGeneral AfterQuestionGeneralPoint"
        }>
            <div className={"AfterQuestionGeneralImg"}>
                <img
                    src={
                        props.page === 'default'?
                            require('../../../images/1.svg').default :
                            require('../../../images/1_grey.svg').default
                    }
                    alt=""
                />
                <img
                    src={
                        props.page === 'gender' ?
                            require('../../../images/gender_page.svg').default :
                            require('../../../images/gender.svg').default
                    }
                    alt=""
                />
                <img
                    src={
                        props.page === 'age' ?
                            require('../../../images/people_page.svg').default :
                            require('../../../images/people.svg').default
                    }
                    alt=""
                />
                <img
                    src={
                        props.page === 'point' ?
                            require('../../../images/point_page.svg').default :
                            require('../../../images/point.svg').default
                    }
                    alt=""
                />
            </div>

            {content(props.page)}

        </div>


    );
}

export default AfterQuestionGeneral;