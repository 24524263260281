import React, {useState} from 'react';
import './firstQuestion.css';

function FirstQuestionContent(props) {

    return (
        <>
            <div className={"FirstQuestionContent"}>
                <div className={"FirstQuestionContentTitle"}>
                    <div>
                        Hi! Let’s start from simple question
                    </div>
                </div>

                <div className={"FirstQuestionContentBlock1"}>
                    Do you have a pet?
                </div>

                <hr/>


                <div className={"FirstQuestionContentCheckBox"}>
                    <div>
                        <input type="checkbox"/>
                        <label htmlFor="">Yes</label>
                    </div>
                    <div>
                        <input type="checkbox"/>
                        <label htmlFor="">No</label>
                    </div>
                    <div>
                        <input type="checkbox"/>
                        <label htmlFor="">Officially no</label>
                    </div>
                </div>


                <div className={"FirstQuestionContentLetsgo"}>submit</div>

                <div className={"FirstQuestionContentFooter"}>
                    {/*<img src={require('../../images/Logo1.svg').default} alt=""/>*/}
                </div>
            </div>
        </>
    )
}

export default FirstQuestionContent;