import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './MainSlider.css';
import Get from '../../Fech/Get';
export const Slider = () => {
    const getSlider1 = async () => {
        let data = await Get.post('get_questions_list_top', {
            key: Get.key,
            hash: Get.userhash,
            // tag: 'All'
        });
        return data;
    }
    const [sliders, setSliders] = useState(null);
    useEffect(() => {
        const as = async () => {
            const data = await getSlider1();
            setSliders(data)
        }
        as();
    }, []);


    return (
        <>

            {sliders == null ?
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper MainSlider"
                >
                    <SwiperSlide>
                        <div className="MainSlider__Item loaded">

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="MainSlider__Item loaded">

                        </div>
                    </SwiperSlide> </Swiper> :
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper MainSlider"
                >
                    {
                        sliders.map((el, index) => {
                            if (el.activity == 1) {
                                return <SwiperSlide key={index}>
                                    <Link to={`/questionpage?id=${el.id}`} className="MainSlider__Item">
                                        <div className={"MainSlider__Item__text"}>{el.name}</div>
                                        <div className={"MainSlider__Item__img"}></div>
                                        <div className={"question_time"}>
                                            <div className={"MainSlider__Item__question"}>{el.respondents} votes</div>
                                            <div className={"MainSlider__Item__time"}>{Get.dateFormatTimestapTime(el.date)}</div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            }
                        })}
                </Swiper>
            }


        </>
    );
}
export default Slider;