import { Outlet, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
export const PrivateRoute = () => {
    var Autch = localStorage.getItem("hash");
    const location = useLocation();

    // localStorage.clear();
    return Autch !== null ? (

        <Outlet />


    ) : (
        <Navigate to="/reg" state={{ from: location }} replace />
    );
};
export default PrivateRoute;
