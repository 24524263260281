import React from 'react';
import FirstQuestionContent from '../components/firstQuestion/firstQuestion';
import Pages from "../components/pages";

function FirstQuestion(props) {
    return (
        <div>
            <FirstQuestionContent></FirstQuestionContent>

            <Pages></Pages>
        </div>
    );
}

export default FirstQuestion;